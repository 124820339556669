.image-plus-text-container {
    display: flex;

}

.image-plus-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    background-color: rgb(252, 252, 252);
}

.image-plus-img {
    margin:  100px 150px;
}

.image-plus-text img {
    width: 100%;
    border-radius: 5px;
}

.our-clients {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 65%;
}

.our-clients h2 {
    text-align: start;
    font-size: 45px;
}

.our-clients p {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #212c52;
    opacity: 0.65;
    
}

.our-clients button {
    padding: 15px 20px;
    background-color: #333333;
    color: white;
    width: max-content;
    font-size: 20px;
    border: none;
    cursor: pointer;
}

.our-clients ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media only screen and (max-width: 970px) {

    .image-plus-text-container {
        flex-wrap: wrap;
    }
    .image-plus-text {
        width: 100%;
        
    }

    .image-plus-text.one {
        padding: 40px 0;
    }

    .image-plus-img {
        margin: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .image-plus-img {
        margin: 20px;
    }
}