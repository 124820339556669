.contact {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../assets/about-us-banner-2.png');
}


.contact-title {
    background-color: rgba(0, 0, 0, 0.603);
    padding: 10px 0;
    color: black;
    overflow: hidden;
    font-family: 'Tinos', serif;
    margin-top: 120px;
}

.contact-title h2 {
    color: white;
}

.contact .footer-before img {
    margin-top: -42px;
}

.contact-container {
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.877);

}

.contact-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    padding: 100px 0;
    box-sizing: border-box;
    min-width: 400px;
}

.contact-form.one {
    transform: translateX(-1px);
}

.contact-form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 60%;
}

.contact-form .contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-form input {
    padding: 10px 15px;
    border: none;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.452);
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
}

.contact-form textarea {
    padding: 10px 15px;
    border: none;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.452);
    width: 100%;
    box-sizing: border-box;
    resize: none;
    margin-bottom: 20px;
    font-size: 16px;
}

.contact-form button {
    padding: 15px 20px;
    background-color: #333333;
    color: white;
    width: max-content;
    font-size: 25px;
    border: none;
}

.contact-form h2 {
    margin-bottom: 20px;
    font-size: 35px;
}

.contact-form h3 {
    margin: 10px 0;
    font-size: 25px;
}

.contact-form a {
    color: rgb(82, 82, 82);
    font-size: 20px;
}

@media only screen and (max-width: 817px) {
    .contact-form {
        width: 100%;
        min-width: 0;
    }

    .contact-form.one form {
        width: 90%;
    }

    .contact-form .contact-info {
        align-items: center;
        text-align: center;
    }


}

@media only screen and (max-width: 700px) {
    .contact-title {
       margin-top:190px;
    }
  }
 
  @media only screen and (max-width: 392px) {
    .contact-title {
       margin-top:307px;
    }
  }