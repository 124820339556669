.mosaic {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    gap: 40px;
    justify-content: center;
    margin-top: 50px;
}

.mosaic-item {
    width: calc(25%);
    overflow: hidden;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.701);
    box-sizing: border-box;
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 10px;
    background-color: rgb(252, 252, 252);
    align-items: center;
    border-radius: 5px;
    transition: transform .5s ease;
    cursor: pointer;
}

.mosaic-item:hover {
    transform: scale(1.05,1.05);
}

.mosaic-text {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    max-width: 60%;
}

h2 {
    text-align: center;
}

h3, h2 {
    color: black;
}

p {
    font-weight: 200;
}

@media only screen and (max-width: 970px) {
    .mosaic-item {
        width: 100%;
        height: max-content;
        margin: 0 20px;
    }

    .mosaic-text {
        max-width: 90%;
    }

    .mosaic-item h2 {
        text-align: start;
    }
}