
.leadership {
   position: absolute;
   background-image: url('../assets/about-us-banner-3.png');
   background-size: cover;
   top: 0;
   left: 0;
}

.leadership-container {
   z-index: 2;
position: relative;
}

.leadership:after {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(87, 106, 124, 0.438);
   z-index: 1;
}


.leadership-title {
    background-color: rgba(0, 0, 0, 0.644);
    padding: 10px 0;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 120px;
    z-index: 2;

}

.leadership-title h2 {
   color: white;
}

.leadership .footer-before {
   background-color: transparent !important;
   position: relative;
   z-index: 2;
 }

 .leadership footer {
   position: relative;
   z-index: 2;
 }


.footer-before img {
  background-color: transparent;
}




 .leadership .mosaic-item h3 {
    text-align: center;
 }

 .leadership .mosaic-item p {
    font-size: 17px;
    font-weight: 200;
    text-align: center;
    opacity: 1;
    color: rgb(59, 59, 59);
 }

 @media only screen and (max-width: 700px) {
   .leadership-title {
      margin-top:190px;
   }
 }

 @media only screen and (max-width: 392px) {
   .leadership-title {
      margin-top:307px;
   }
 }

