footer {
    display: flex;
    flex-direction: column;
    
    gap: 5px;
    padding: 10px;
    background-color: transparent;
    margin-top: -50px;
    
}

.footer-before {
    width: 100%;
    display: flex;
    background-color: rgb(252, 252, 252);
    background: #131313;
    height: 60px;
    
}

.footer-before img {
    width: 100%;
}
.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}


.footer-section.one img {
    max-width: 170px;
    width: 100%;
}

.sitemap {
    display: flex;
}

footer h3 {
    font-weight: 400;
}

footer ul {
    display: flex;
    gap: 20px;
    list-style: none;
    
}

.creditos p {
    color: #daa520;
    opacity: 10;
    text-align: center;
}

footer a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    opacity: 1;
    color: white;
}


@media only screen and (max-width: 970px) {
    footer {
        flex-wrap: wrap;
    }

    

    .footer-section {
        width: 100%;
        height: max-content;
    }

}




