.diferencials-1 {
    background-color: white;
    padding: 10px 0;
    color: var(--dark-blue);
    display: flex;
    justify-content: center;
}


.diferencials h3 {
    text-align: center;
}

.diferencials-2 {
    position:relative;
}

.diferencials-slider-container {
    display: flex;
    overflow-x: hidden;
    
}

.slider-controller {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 15px;
}

.prev, .next {
    cursor: pointer;
}

.diferencials-slider {
    min-width: 25%;
    box-sizing: border-box;
    height: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #2b2b2b;;
    outline: 1px solid rgba(128, 128, 128, 0.308);
    padding: 20px 40px;
}


.diferencials-slider h2 {
    font-size: 50px;
    color: white;
}

.diferencials-slider p {
    font-size: 17px;
    color: white;
    opacity: 1;
}

@media only screen and (max-width: 970px) {
    .diferencials-slider {
        min-width: 50%;
        padding: 70px 20px 20px 20px;
        height: 50vw;
    }
}

@media only screen and (max-width: 630px) {
    .diferencials-slider {
        min-width: 100%;
        height: 100vw;
    }
}