.about-us .mosaic-item {
    height: initial;
    align-items: flex-start;
    justify-content: flex-start;
}

.about-us {
    position: absolute;
    top: 100vh;
    left: 0;
    width: 100%;
    z-index: -2;
}

.about-us .mosaic-item h3 {
    text-align: center;
    color: grey;
    font-size: 18px;
    margin-bottom: 10px;
}

.about-us-title {
    color: black;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-top: 120px;
    padding: 10px;
}

.about-us-title h2 {
    color: white;
    z-index: 999;
}

.about-us-text {
    display: flex;
    background-image: url('../assets/background-1.jpeg');
    position: relative;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-us-text::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.205);
    z-index: 1;
}



.textss {
    
    padding: 50px 150px;
    z-index: 2;
    padding-bottom: 36px;
    
}

.texts-container {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.644);
    padding: 20px;
    border-radius: 5px;
    background-color: rgb(19, 19, 19);
    border: 1px solid #9a7720;
}

.textss h3 {
    margin-bottom: 10px;
}

.textss p {
    color: #ffffff;
    opacity: 1;
    
}

.withus-cards {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    
    z-index: 9999;
    flex-wrap: wrap;
    padding: 0 150px;
    padding-bottom: 115px;
    cursor: pointer;
    overflow: hidden;
}

.contact-us {
background: linear-gradient(180deg, transparent 25%, #4a3605);    z-index: 9999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
    
}

.contact-us-column {
    
}

.contact-us-container a {
    text-decoration: none;
}

.contact-us-container {
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 40px;
}

.contact-us a {
    color: white;
    font-size: 22px;
}

.contact-us h3 {
    color: white;
    font-size: 30px;
    margin-top: 10px;
}

.contact-us h2 {
   color: white;
   margin-bottom: 56px;
   

}


.withus-card {
    width: 26%;
    background-color: rgb(19, 19, 19);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.473);
    display: flex;
    border: 1px solid #9a7720;
    flex-direction: column;
    align-items: center;
    padding: 20px 5px;
    box-sizing: border-box;
    gap: 15px;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    transition: transform .5s ease;
}

.withus-card h3 {
    color: white;
}


.withus-card:hover {
    transform: scale(1.05,1.05);
}


.about-us-text p {
    font-size: 21px;
    font-weight: 400;
    margin-bottom: 20px;
}



.benefits-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding:  30px 0;
    background-color: rgb(19, 19, 19);
    margin: 0 150px;
    border-radius: 5px;
    border: 1px solid #9a7720;
}

.benefits-card {
    width: 25%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 30px 0;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform .5s ease;
}



.benefits-card:hover {
   transform: scale(1.1,1.1);
}

.benefits {
    
    z-index: 990;
    padding-bottom: 36px;
}
.benefits-card p {
    opacity: 1;
    font-weight: 700;
    font-size: 19px;
    text-align: center;
    color: white;
}

.benefits-h2 {
    
    padding: 10px 0;
    color: white;
    display: flex;
    justify-content: center;
    z-index: 999;
    padding-bottom: 40px;
}
.imgss {
    width: 50%;
}

.imgss img {
    width: 100%;
    box-shadow: 10px 10px 1px black;
}

.withus h2 {
    padding-bottom: 40px;
}


.banner-about-text {
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.616);
    padding: 10px;
}

.banner-about-text h2 {
    color: white;
    width: max-content; 
    z-index: 999;
}

.banner-about {
    
    width: 100%;
    background: url('../assets/about-us-banner-4.png');
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    position: relative;
    z-index: -2;
}

.banner-about::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.199);
    z-index: -1;
}

.banner-about img {
    width: 100%;
}

.our-team-container {
    display: flex ;
    flex-wrap: wrap;
    padding: 50px 150px;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
}

.our-team-card {
    display: flex;
    flex-direction: column;
   padding: 15px;
    box-sizing: border-box;
    cursor: pointer;
    width: 25%;

}

.our-team-text {
    border-top: 5px solid #2e2e2e;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.87);
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.our-team-text h3 {
    color: black;
    
}

.our-team-text p {
    font-weight: 300;
    color: black;
    opacity: 1;
}

.our-team-card img {
    aspect-ratio: 1 / 1;
}


.team-info.active {
    display: flex;
    opacity: 1;
    
}

.team-info {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.863);
    z-index: 999999999;
    opacity: 0;
    transition: opacity 1s ease;
}

.team-info-container {
    background-color: white;
    width: 1000px;
    display: flex;
    flex-wrap: wrap;
    height: 500px;
    overflow: hidden;
}



.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.close-button button {
    background-color: red;
    color: white;
    padding: 2px 8px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.team-info-text p {
    margin-bottom: 10px;
    opacity: 1;
    color: #333333;
    font-weight: 300;
}

.team-info-text h2 {
    margin-bottom: 10px;
    opacity: 1;
    color: #333333;
    font-weight: 600;
    text-align: left;   
    line-height: 20px;
}

.team-info-text h3 {
    margin-bottom: 10px;
    opacity: 1;
    color: #333333;
    font-weight: 400;
    font-size: 20px;
}

.team-info-img  {
    width: 50%;
}

.team-info img  {
    width: 100%;
    min-width: 300px;
}

.team-info-text {
    overflow: auto;
    padding: 20px;
    position: relative;
    width: 50%;
    box-sizing: border-box;
    height: 100%;
}


@media only screen and (max-width: 1300px) {
    .our-team-card {

        width: 40%;
    }
    
}

@media only screen and (max-width: 1000px) {
    .team-info-container {
        width: 100%;
        height: 100%;
       
    }
    .team-info-img  {
        width: 50%;
        height: 50vh;
        overflow: hidden;

    }

    .team-info-text {
        height: 50vh;
        width: 100%;
       
    }

    .contact-us-container {
        margin-bottom: 50px
    }
}



@media only screen and (max-width: 940px) {
    .our-team-card {
        width: 80%;
    }

    

    .textss {
        margin: 30px;
        padding: 20px;
    }

    .benefits-container {
        margin: 0 20px;
    }

    .withus-cards {
        padding: 0 20px;
    }
    
}

@media only screen and (max-width:780px) {
    .team-info-img {
        width: 100%;
    }

    .textss {
        margin: 15px;
    }

    .about-us-title {
        margin-top: 140px;
    }
    
}

@media only screen and (max-width: 700px) {
    
   .about-us-title {
    margin-top: 190px;

    
}

.withus-cards {
    gap: 20px;
}
.withus-card {
    width: 100%;
    margin: 0; 
}


}

@media only screen and (max-width:600px) {
    .our-team-card { 
        width: 100%;
    }

    .our-team-container {
        padding: 50px;
    }


    .textss {
        margin: 15px 0;
        padding: 15px;
    }
    
}


@media only screen and (max-width: 490px) {
    
    .banner-about {
     padding-top: 0;
    } 
 }

@media only screen and (max-width:392px) {
    .our-team-card {
        width: 100%;
    }

    .our-team-container {
        padding: 10px;
    }

    header {
        gap: 50px;
    }

    .about-us-title {
        margin-top: 307px;
    }

}