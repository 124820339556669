.front-page {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 100%;
}

.novo-logo {
    position: relative;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    width: 300px;
}


.banner {
    width: 100%;
    background-blend-mode: multiply;
    background-color: #828c9b;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
    box-sizing: border-box;
    overflow: hidden;
}

.banner video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-text {
    position: absolute;
    bottom: 13%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    z-index: 10;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%) !important;
    text-align: center;
    
}

.button-custom {
    margin-top: 40px;
}

.banner::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.banner h2 {
    z-index: 3;
    color: white;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.541);
    letter-spacing: 1px;
    font-size: 70px;
    line-height: 94px;
    text-align: center;
    
}

.banner button {
    padding: 15px 20px;
    background-color: #000000c7;
    color: white;
    width: max-content;
    font-size: 25px;
    border: none;
    box-shadow: 1px 1px 5px rgba(37, 37, 37, 0.801);
    z-index: 200;
    cursor: pointer;
    border-radius: 3px;
}


@media only screen and (max-width: 800px) {
    .front-page {
        
    }

    .banner-text {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        
    }

    .banner-text h2 {
        font-size: 46px;
        text-align: center;
        line-height: 62px;

    }
}

@media only screen and (max-width: 500px) {
    .banner h2 {
        font-size: 30px;
    }
}