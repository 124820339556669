.opening-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(10, 10, 10);
    z-index: 99999;
    animation: moves .8s ease 2.5s forwards;
}

@keyframes moves {
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.opening-loading img {
    width: 200px;
    opacity: 0;
    transform: translateY(100px);
    animation: pulse 2.5s forwards;
}

@keyframes pulse {
    100% {opacity: 1; transform: translateY(0);}
}

