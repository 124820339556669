

header {
    background-image: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.699), rgba(0, 0, 0, 0));
    display: flex;
    align-items: center;
    position: relative;
    padding: 30px 150px;
    z-index: 2;
    
}

.logo {
    flex: 3;
    opacity: 0;
    animation: logoEffect 1s forwards ease;
}

@keyframes logoEffect {
    from {transform: translateX(-100px);}
    to {transform: translateX(0); opacity: 1;}
}

.logo img {
    width: 150px;
}


.withus {
 
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.withus h2 {
   color: white;
   z-index: 9999;
}

.nav {
    padding: 5px;
    border-radius: 3px;
}



.nav ul {
    display: flex;
}

.nav ul {
    display: flex;
    gap: 20px;
    list-style: none;
    padding: 4px 12px;
}


.nav li a {
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.nav a:hover{
    border-bottom: 3px solid white;

}


@media only screen and (max-width: 700px) {
    header {
        flex-wrap: wrap;
        align-items: center ;
        justify-content: center;
        padding: 50px;
        gap: 20px;
    }

    .logo {
        display: flex;
        justify-content: center;
    }


    .nav ul {
        flex-direction: column;
        gap: 10px;
        
    } 
}