.scroll-fade {
    opacity: 0;
    transform: translateY(150px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .scroll-fade-in {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
  }
  